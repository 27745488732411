import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"
import 'bootstrap/dist/css/bootstrap.min.css';

// <Status />

const Index = () => (
  <Layout>
    
    <View>
      <div className="text-center d-flex" style={{ height: 300, justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ fontSize: 30 }}>
          <span role="img">👋</span>
        </div>
        <button class="btn btn-lg" type="button">
          <Link to="/app/login">Log in</Link>
        </button>
      </div>
    </View>
  </Layout >
)

export default Index
